import React from 'react';

import './style.css';
import {
    Row, Col, Image,
} from 'react-bootstrap';

const PeopleComponent = (prop) => (
    <Col lg={3} sm={6} className="text-center">
        <Image
            className="p-5 p-sm-2 p-md-3"
            src={prop.person.image}
            alt="person"
            fluid
            roundedCircle
            style={{
                width: '240px',
                height: '240px',
                objectFit: 'cover',
                objectPosition: 'center',
            }}
        />
        <h4>
            <a href={prop.person.link} className="gs-team-name" target="_blank" rel="noopener noreferrer">
                {prop.person.name}
            </a>
        </h4>
        <p>
            <b>{prop.person.position}</b>
            <br />
            {prop.person.title}
        </p>
    </Col>
);

const TeamSectionComponent = (prop) => {
    const people = prop.teamSection.people.map((person, i) => <PeopleComponent key={i} person={person} />);
    return (
        <Row>
            { people }
        </Row>
    );
};

export default TeamSectionComponent;
