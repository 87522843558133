import { PEOPLE_IMAGE } from '../util/constants';

export const teamSections = [
    {
        teamName: 'Leadership Team',
        people: [
            {
                name: 'Angel Yu',
                title: '',
                position: 'Co-executive Director',
                image: PEOPLE_IMAGE.angel_yu,
                link: '',
            },
            {
                name: 'Lewis Liu',
                title: '',
                position: 'Co-executive Director',
                image: PEOPLE_IMAGE.lewis_liu,
                link: '',
            },
            {
                name: 'Saad Shan',
                title: '',
                position: 'Chief of Staff',
                image: PEOPLE_IMAGE.saad_shan,
                link: '',
            },
            {
                name: 'Krish Bandivdekar',
                title: '',
                position: 'Chief of Staff',
                image: PEOPLE_IMAGE.krish_bandivdekar,
                link: '',
            },
            {
                name: 'Summer Deng',
                title: '',
                position: 'Co-Head of HTG',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Máté Baltay',
                title: '',
                position: 'Co-Head of HTG',
                image: PEOPLE_IMAGE.mate_baltay,
                link: '',
            },
            {
                name: 'Annabeth Lin',
                title: '',
                position: 'Co-Head of Spark Ventures',
                image: PEOPLE_IMAGE.annabeth_lin,
                link: '',
            },
            {
                name: 'Shajan Kamalanathan',
                title: '',
                position: 'Co-Head of Spark Ventures',
                image: PEOPLE_IMAGE.shajan_kamalanathan,
                link: '',
            },
            {
                name: 'Billy Shi',
                title: '',
                position: 'Head of Digital & Analytics',
                image: PEOPLE_IMAGE.billy_shi,
                link: '',
            },
            {
                name: 'Deep Leekha',
                title: '',
                position: 'Co-Head of Sponsorships & Partnerships',
                image: PEOPLE_IMAGE.deep_lekha,
                link: '',
            },
            {
                name: 'Lili Lantos',
                title: '',
                position: 'Co-Head of Sponsorships & Partnerships',
                image: PEOPLE_IMAGE.lili_lantos,
                link: '',
            },
            {
                name: 'Radin Hakimjavadi',
                title: '',
                position: 'Co-Head of Marketing',
                image: PEOPLE_IMAGE.radin_hakimjavadi,
                link: '',
            },
            {
                name: 'Martina Facchin',
                title: '',
                position: 'Co-Head of Marketing',
                image: PEOPLE_IMAGE.martina_facchin,
                link: '',
            },
            {
                name: 'Catherine Kawpeng',
                title: '',
                position: 'Head of People & Culture',
                image: PEOPLE_IMAGE.catherine_kawpeng,
                link: '',
            },
        ],
    },
    {
        teamName: 'Hack the Globe',
        people: [
            {
                name: 'Dhruv Leekha',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.dhruv_leekha,
                link: '',
            },
            {
                name: 'Shashwat Parikh',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.shashwat_parikh,
                link: '',
            },
            {
                name: 'Leanna Lui',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.leanna_lui,
                link: '',
            },
            {
                name: 'Zhi Ling Jiang',
                title: '',
                position: 'Sr. Logistics Lead',
                image: PEOPLE_IMAGE.zhi_ling_jiang,
                link: '',
            },
            {
                name: 'Shaba Khan',
                title: '',
                position: 'Sr. Logistics Lead',
                image: PEOPLE_IMAGE.shaba_khan,
                link: '',
            },
            {
                name: 'Edward He',
                title: '',
                position: 'Sr. Logistics Lead',
                image: PEOPLE_IMAGE.edward_he,
                link: '',
            },
            {
                name: 'Armin Kusmic',
                title: '',
                position: 'Sr. Logistics Lead',
                image: PEOPLE_IMAGE.armin_kusmic,
                link: '',
            },
            {
                name: 'Ekam Sidhu',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.ekam_sidu,
                link: '',
            },
            {
                name: 'Lalou Abdie',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.lalou_abdie,
                link: '',
            },
            {
                name: 'Chaewon Kang',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.chaewon_kang,
                link: '',
            },
            {
                name: 'Misheel Batkhuu',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.misheel_batkhuu,
                link: '',
            },
            {
                name: 'Sophia Ramji',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Humdaan Javed',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.humdaan_javed,
                link: '',
            },
            {
                name: 'Dante Dorr',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.dante_dorr,
                link: '',
            },
            {
                name: 'Vanessa Tsui Ern',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.vanessa_tsui,
                link: '',
            },
            {
                name: 'Amyna Ismail',
                title: '',
                position: 'Logistics Lead',
                image: PEOPLE_IMAGE.amyna_ismail,
                link: '',
            },
        ],
    },
    {
        teamName: 'Spark Ventures',
        people: [
            {
                name: 'Jenna Ramji',
                title: '',
                position: 'MD',
                image: PEOPLE_IMAGE.jenna_ramji,
                link: '',
            },
            {
                name: 'Maharshi Desai',
                title: '',
                position: 'MD',
                image: PEOPLE_IMAGE.maharshi_desai,
                link: '',
            },
            {
                name: 'Baihe Chen',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.baihe_chen,
                link: '',
            },
            {
                name: 'Laurel Dong',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.laurel_dong,
                link: '',
            },
            {
                name: 'Belinda Zhao',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.belinda_zhao,
                link: '',
            },
            {
                name: 'Jerry Wu',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.jerry_wu,
                link: '',
            },
            {
                name: 'Vedant Iyer',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.vedant_iyer,
                link: '',
            },
            {
                name: 'Shirley Mu',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.shirley_mu,
                link: '',
            },
            {
                name: 'Shaun Sunil',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.shaun_sunil,
                link: '',
            },
            {
                name: 'Andrew Sun',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.andrew_sun,
                link: '',
            },
            {
                name: 'Jasmine Virdee',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.jasmin_virdee,
                link: '',
            },
        ],
    },
    {
        teamName: 'Sponsors & Partnerships',
        people: [
            {
                name: 'Ashutosh Verma',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Donna Yang',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.donna_yang,
                link: '',
            },
            {
                name: 'Elliot Lam',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.elliot_lam,
                link: '',
            },
            {
                name: 'Kushagra Jindal',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.kushagra_jindal,
                link: '',
            },
            {
                name: 'Victoria Lobo',
                title: '',
                position: 'Managing Director',
                image: PEOPLE_IMAGE.victoria_lobo,
                link: '',
            },
            {
                name: 'Nicholas Palacio',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.nicholas_palacio,
                link: '',
            },
            {
                name: 'Helena Jovic',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.helena_jovic,
                link: '',
            },
            {
                name: 'George Chan',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.george_chan,
                link: '',
            },
            {
                name: 'Miranda Yang',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Joanne Yuan',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.joanne_yuan,
                link: '',
            },
            {
                name: 'Hannah Mahr',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.hannah_mahr,
                link: '',
            },
            {
                name: 'Jerry Lam',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.jerry_lam,
                link: '',
            },
            {
                name: 'Ananya Tandon',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.ananya_tandon,
                link: '',
            },
            {
                name: 'Rebeca Alarcon',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.rebeca_alarcon,
                link: '',
            },
            {
                name: 'Izora Kayumova',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.izora_kayumova,
                link: '',
            },
            {
                name: 'Yonir Jain',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Ariza Hossain',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.ariza_hossain,
                link: '',
            },
            {
                name: 'Shub Atreya',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Natasha Pereira',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.natasha_pereira,
                link: '',
            },
            {
                name: 'Andrew Song',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.andrew_song,
                link: '',
            },
            {
                name: 'Hasna Hafidzah',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.hasna_hafidzah,
                link: '',
            },
            {
                name: 'Ray Fang',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.ray_fang,
                link: '',
            },
            {
                name: 'Fatimah Nadir',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.fatimah_nadir,
                link: '',
            },
            {
                name: 'Melody Nguyen',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.melody_nguyen,
                link: '',
            },
            {
                name: 'Audrey Gong',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.audrey_gong,
                link: '',
            },
            {
                name: 'Kate Chang',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.kate_chang,
                link: '',
            },
            {
                name: 'Zoey Hou',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.zoie_hou,
                link: '',
            },
            {
                name: 'Joy Lin',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.joy_lin,
                link: '',
            },
        ],
    },
    {
        teamName: 'Digital & Analytics',
        people: [
            {
                name: 'Jason Le',
                title: '',
                position: 'Software Engineering Manager',
                image: PEOPLE_IMAGE.jason_le,
                link: '',
            },
            {
                name: 'Gerry Chen',
                title: '',
                position: 'Software Engineering Manager',
                image: PEOPLE_IMAGE.gerry_chen,
                link: '',
            },
            {
                name: 'Aania Shah',
                title: '',
                position: 'Data Analytics Manager',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Martina Bravo',
                title: '',
                position: 'Senior UX/UI Designer Lead',
                image: PEOPLE_IMAGE.martina_bravo,
                link: '',
            },
            {
                name: 'Stephenie Doan',
                title: '',
                position: 'Senior UX/UI Designer Lead',
                image: PEOPLE_IMAGE.stephanie_doan,
                link: '',
            },
            {
                name: 'Rena Doan',
                title: '',
                position: 'Senior Developer Lead',
                image: PEOPLE_IMAGE.rena_doan,
                link: '',
            },
            {
                name: 'Meriem Mehri',
                title: '',
                position: 'Data Analytics Lead',
                image: PEOPLE_IMAGE.meriem_mehri,
                link: '',
            },
            {
                name: 'Emmeline Handojo',
                title: '',
                position: 'Data Analytics Lead',
                image: PEOPLE_IMAGE.emmeline_handojo,
                link: '',
            },
            {
                name: 'Prisha Tharwani',
                title: '',
                position: 'Data Analytics Lead',
                image: PEOPLE_IMAGE.prisha_tharwani,
                link: '',
            },
            {
                name: 'Emma He',
                title: '',
                position: 'Data Analytics Lead',
                image: PEOPLE_IMAGE.emma_he,
                link: '',
            },
            {
                name: 'Dylan O\'Toole',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.dylan_otoole,
                link: '',
            },
            {
                name: 'Neema Mkenda',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.neema_mkenda,
                link: '',
            },
            {
                name: 'Alexander Yuyitung',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.alexander_yuyitung,
                link: '',
            },
            {
                name: 'Jeffrey Xuzhang',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.jeffrey_xuzhang,
                link: '',
            },
            {
                name: 'Anthony Wang',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.anthony_wang,
                link: '',
            },
            {
                name: 'Kevin Cui',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.kevin_cui,
                link: '',
            },
            {
                name: 'Gregory Young',
                title: '',
                position: 'Software Developer Lead',
                image: PEOPLE_IMAGE.gregory_young,
                link: '',
            },
        ],
    },
    {
        teamName: 'Marketing',
        people: [
            {
                name: 'Sophie Yau',
                title: '',
                position: 'Managing Director of Design',
                image: PEOPLE_IMAGE.sophie_yau,
                link: '',
            },
            {
                name: 'Daniel Tran',
                title: '',
                position: 'Managing Director of Marketing Strategy',
                image: PEOPLE_IMAGE.daniel_tran,
                link: '',
            },
            {
                name: 'Joyce Xuan',
                title: '',
                position: 'Managing Director of Outreach',
                image: PEOPLE_IMAGE.joyce_xuan,
                link: '',
            },
            {
                name: 'Ally Yuen',
                title: '',
                position: 'Design Lead',
                image: PEOPLE_IMAGE.ally_yuen,
                link: '',
            },
            {
                name: 'Zainab Goriawala',
                title: '',
                position: 'Design Lead',
                image: PEOPLE_IMAGE.zainab_goriawala,
                link: '',
            },
            {
                name: 'Devashi Gupta',
                title: '',
                position: 'Design Lead',
                image: PEOPLE_IMAGE.devashi_gupta,
                link: '',
            },
            {
                name: 'Devika Tomar',
                title: '',
                position: 'Design Lead',
                image: PEOPLE_IMAGE.devika_tomar,
                link: '',
            },
            {
                name: 'Rishika Jain',
                title: '',
                position: 'Marketing Lead',
                image: PEOPLE_IMAGE.rishika_jain,
                link: '',
            },
            {
                name: 'Ishleen Wadehra',
                title: '',
                position: 'Marketing Lead',
                image: PEOPLE_IMAGE.ishleen_wadehra,
                link: '',
            },
            {
                name: 'Georgie Burr',
                title: '',
                position: 'Marketing Lead',
                image: PEOPLE_IMAGE.default,
                link: '',
            },
            {
                name: 'Alison Chong',
                title: '',
                position: 'Marketing Lead',
                image: PEOPLE_IMAGE.alison_chong,
                link: '',
            },
        ],
    },
    {
        teamName: 'People & Culture',
        people: [
            {
                name: 'Samantha Vu',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.samantha_vu,
                link: '',
            },
            {
                name: 'Seline Kawpeng',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.seline_kawpeng,
                link: '',
            },
            {
                name: 'Jia Yi Deng',
                title: '',
                position: 'Sr. Lead',
                image: PEOPLE_IMAGE.jia_yi_deng,
                link: '',
            },
            {
                name: 'Ahri Wu',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.ahri_wu,
                link: '',
            },
            {
                name: 'Areeya Hanvichit',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.areeya_hanvi,
                link: '',
            },
            {
                name: 'Jessica Oh',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.jessica_oh,
                link: '',
            },
            {
                name: 'Mahrosh Zafar',
                title: '',
                position: 'Lead',
                image: PEOPLE_IMAGE.mahrosh_zafar,
                link: '',
            },
        ],
    },
    {
        teamName: 'Board and Advisors',
        people: [
            {
                name: 'Malik Ismail (Chair)',
                title: 'Founder & Fmr. Exec. Director',
                position: 'Board Member',
                image: PEOPLE_IMAGE.malik_ismail,
                link: '',
            },
            {
                name: 'Julie Moysiuk',
                title: 'Fmr. Head of SICP, Speaker Panel',
                position: 'Board Member',
                image: PEOPLE_IMAGE.julie_moysiuk,
                link: '',
            },
            {
                name: 'Henry Zhang',
                title: 'Fmr. Head of Curriculum',
                position: 'Board Member',
                image: PEOPLE_IMAGE.henry_zhang,
                link: '',
            },
            {
                name: 'Dasha Pushkareva',
                title: 'Fmr. Head of Marketing & Digital',
                position: 'Board Member',
                image: PEOPLE_IMAGE.dasha_pushkareva,
                link: '',
            },
            {
                name: 'John Kim',
                title: 'Fmr. Head of Sponsorships & Partnerships',
                position: 'Board Member',
                image: PEOPLE_IMAGE.john_kim,
                link: '',
            },
            {
                name: 'Alyf Janmohamed',
                title: 'Fmr. Head of Speaker Panel',
                position: 'Board Member',
                image: PEOPLE_IMAGE.alyf_janmohamed,
                link: '',
            },
            {
                name: 'Hui Wen Zheng',
                title: 'Fmr. Co-executive Director',
                position: 'Board Member',
                image: PEOPLE_IMAGE.huiwen_zheng,
                link: '',
            },
            {
                name: 'Ben Mucsi',
                title: 'Fmr. Co-Executive Director',
                position: 'Board Member',
                image: PEOPLE_IMAGE.ben_mucsi,
                link: '',
            },
            {
                name: 'Kate Banting',
                title: 'Head of Social Impact & Marketing at BCG Canada',
                position: 'Advisor',
                image: PEOPLE_IMAGE.kate_banting,
                link: '',
            },
            {
                name: 'Christopher Yip',
                title: 'Dean of Engineering at UofT',
                position: 'Advisor',
                image: PEOPLE_IMAGE.christopher_yip,
                link: '',
            },
        ],
    },
];
