import React from 'react';
// import { Row, Col, Container } from 'react-bootstrap';
import { SICP_IMAGE } from '../util/constants';

// import CompaniesLogoComponent from '../home/CompaniesLogoComponent';
// import BodyComponent from './BodyComponent';
import Landing from './Landing';
import WhatIsSICPComponent from './WhatIsSICPComponent';
import HowItWorksComponent from './HowItWorksComponent';
import ProjectScopeComponent from './ProjectScopeComponent';
import TimelineComponent from './TimelineComponent';
import ClientsComponent from './ClientsComponent';
import JoinProgramComponent from './JoinProgramComponent';
import './style.css';
// import { logoSections } from '../home/util';

function SICPComponent() {
    return (
        <div className="font-sicp">
            <Landing
                image={SICP_IMAGE.sicp_intro_background}
                width={1270}
                height={90}
            />
            <WhatIsSICPComponent />
            <HowItWorksComponent />
            <ProjectScopeComponent />
            <TimelineComponent />
            <ClientsComponent />
            <JoinProgramComponent />
        </div>
    );
}

export default SICPComponent;
