import React, { useState } from 'react';
import Typed from 'react-typed';
import {
    Row, Col, Image, Container,
} from 'react-bootstrap';

import CompaniesLogoComponent from './CompaniesLogoComponent';
import { HOME_IMAGE } from '../util/constants';

import { logoSections, typeStrings, typeStringColours } from './util';
// import Slider from "react-slick";

// NOTE: go over the padding issue (currently fix it by adding 27rem top padding)
// Note: commented out upon Malik's request
// const HpStatCarouselComponent = () => {
//     var slideToShow = 3;
//     var slideToScroll = 3;
//     if (window.innerWidth < 415) {
//         slideToShow = 1
//         slideToScroll = 1
//     }
//     const settings = {
//         infinite: true,
//         speed: 500,
//         slidesToShow: slideToShow,
//         slidesToScroll: slideToScroll,
//         autoplaySpeed: 3000,
//         autoplay: true
//     };

//     return (
//         <div>
//         <Slider {...settings}>
//           <div>
//             <h3><b>11</b></h3>
//             Universities
//           </div>
//           <div>
//             <h3><b>100+</b></h3>
//             Course Partnerships
//           </div>
//           <div>
//             <h3><b>20000+</b></h3>
//             Students Engaged
//           </div>
//           <div>
//             <h3><b>40+</b></h3>
//             Instituitions Reached
//           </div>
//           <div>
//             <h3><b>900+</b></h3>
//             Speaker Panel Registrations
//           </div>
//           <div>
//             <h3><b>21</b></h3>
//             Countries
//           </div>
//         </Slider>
//       </div>
//     );
// };

const LandingComponent = () => {
    const [typedObj, setTyped] = useState(null);
    const [curStringIndex, setCurStringIndex] = useState(0);
    const [curString, setCurString] = useState(['leaders', '']);
    const [curStringColour, setCurStringColour] = useState('text-yellow');
    const completeHandler = () => {
        typedObj.reset();
        if (curStringIndex === 2) {
            setCurString([typeStrings[0], '']);
            setCurStringColour(typeStringColours[0]);
            setCurStringIndex(0);
        } else {
            setCurString([typeStrings[curStringIndex + 1], '']);
            setCurStringColour(typeStringColours[curStringIndex + 1]);
            setCurStringIndex(curStringIndex + 1);
        }
    };

    return (
        <div
            className="gs-home-bg"
            style={{ backgroundImage: `url(${HOME_IMAGE.bg.default})` }}
        >
            <Container
                data-aos="fade-up"
                className="gs-block gs-landing gs-home-landing"
            >
                <div className="gs-block w-75 w-sm-50 position-absolute b-0 r-0">
                    <Image src={HOME_IMAGE.landing.default} fluid />
                </div>
                <div
                    className="gs-home-landing-text position-absolute"
                    data-aos="fade-up"
                >
                    <h1 className="pb-2 display-4">
                        Sparking the next generation of
                        {' '}
                        <Typed
                            onComplete={completeHandler}
                            typedRef={(typed) => {
                                setTyped(typed);
                            }}
                            className={curStringColour}
                            strings={curString}
                            typeSpeed={30}
                            backSpeed={30}
                            backDelay={1300}
                            showCursor
                        />
                        to tackle the world's most pressing challenges.
                    </h1>
                </div>
                {/* <div className="gs-home-landing-text pt-20">
                    <HpStatCarouselComponent/>
                </div> */}
            </Container>
            <Container data-aos="fade-up" className="pt-0 gs-block">
                <Row>
                    <Col md={8}>
                        <h1 className="display-4 font-weight-bolder">Global Spark</h1>
                        <p>
                            <b>
                                educates and mobilizes thousands of university students in
                                STEM, social sciences, and business in social entrepreneurship
                                and global development.
                            </b>
                        </p>
                    </Col>
                </Row>
            </Container>
            <CompaniesLogoComponent
                logoSections={logoSections}
                className="home-logos-section"
                heading="Organizations We've Worked With"
                desc="We partner with organizations from around the globe to provide innovative learning opportunities for students."
            />
        </div>
    );
};

export default LandingComponent;
